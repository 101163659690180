import React, { useState, useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ColumnFilter from "./ColumnFilter";

const SaleOrderSheet = () => {
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "",
      field: "row",
    },
    { headerName: "", field: "row2", width: 100 },
    {
      headerName: "POTHYS INTERNATIONAL LIMITED",
      children: [
        { headerName: "36", field: "pothys36", width: 60 },
        { headerName: "38", field: "pothys38", width: 60 },
        { headerName: "40", field: "pothys40", width: 60 },
        { headerName: "42", field: "pothys42", width: 60 },
        { headerName: "44", field: "pothys44", width: 60 },
        { headerName: "46", field: "pothys46", width: 60 },
        { headerName: "48", field: "pothys48", width: 60 },
        { headerName: "Total", field: "pothysTotal", width: 100 },
      ],
    },
    {
      headerName: "NAIDU HALL FAMILY STORES CHENNAI",
      children: [
        { headerName: "36", field: "naidu36", width: 60 },
        { headerName: "38", field: "naidu38", width: 60 },
        { headerName: "40", field: "naidu40", width: 60 },
        { headerName: "42", field: "naidu42", width: 60 },
        { headerName: "44", field: "naidu44", width: 60 },
        { headerName: "46", field: "naidu46", width: 60 },
        { headerName: "48", field: "naidu48", width: 60 },
        { headerName: "Total", field: "naiduTotal", width: 100 },
      ],
    },
  ]);

  const [rowData, setRowData] = useState([
    {
      row: "ratio",
      pothys36: 3,
      pothys38: 4,
      pothys40: 2,
      pothys42: 5,
      pothys44: 7,
      pothys46: 3,
      pothys48: 2,
      pothysTotal: 26,
      naidu36: 4,
      naidu38: 4,
      naidu40: 2,
      naidu42: 5,
      naidu44: 6,
      naidu46: 0,
      naidu48: 2,
      naiduTotal: 23,
    },
    {
      row: "PINTUCK SETS",
      row2: "Pink",
      pothys36: 3,
      pothys38: 4,
      pothys40: 2,
      pothys42: 5,
      pothys44: 1,
      pothys46: 3,
      pothys48: 2,
      pothysTotal: 20,
      naidu36: 4,
      naidu38: 4,
      naidu40: 2,
      naidu42: 5,
      naidu44: 6,
      naidu46: 0,
      naidu48: 2,
      naiduTotal: 23,
    },
  ]);

  const defaultCofDef = useMemo(() => {
    return {
      editable: true,
    };
  });

  function handleAddCustomer() {}

  return (
    <div className="ag-theme-alpine" style={{ height: 500, width: "100%" }}>
      <ColumnFilter setColumnDefs={setColumnDefs} columnDefs={columnDefs} />

      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultCofDef}
      />
    </div>
  );
};

export default SaleOrderSheet;
