import { Button } from "antd";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, getFormValues, reduxForm } from "redux-form";
import {
  getDropdownList,
  getMasterDropDownList,
  masterSelectors,
} from "slices/masterSlice";
import { ReduxFormSelectField2 } from "utils/ReduxFormSelectField";

const customerDropdownMaster = "customer_dropdown";

function ColumnFilter({ setColumnDefs, columnDefs }) {
  const formValues = useSelector(getFormValues("column-filter"));

  const dispatch = useDispatch();

  const customerDropDown = useSelector(masterSelectors?.getCustomerDropdown);
  // console.log(customerDropDown);

  useEffect(() => {
    dispatch(getMasterDropDownList({ masterName: customerDropdownMaster }));
  }, []);

  const [show, setShow] = useState(false);

  function handleAddCustomer() {
    const val = {
      customer: formValues?.customer?.map((item) => item?.label) || [],
    };
    console.log(val.customer);
    const customerArray = columnDefs;
    console.log(customerArray);

    // setColumnDefs(...customerArray,)
  }

  // function addNewColumns(newColumnsArray) {
  //   // Get the current column definitions
  //   const currentColumns = gridOptions.columnDefs;

  //   // Create new column definitions based on the input array
  //   const newColumns = newColumnsArray.map(newCol => ({
  //     headerName: newCol.headerName,
  //     field: newCol.field,
  //     // Add other column properties as needed
  //   }));

  //   // Add the new columns to the existing columns
  //   const updatedColumns = [...currentColumns, ...newColumns];

  //   // Update the grid with new column definitions
  //   gridOptions.api.setColumnDefs(updatedColumns);

  //   // If you need to add data for the new columns:
  //   const rowData = gridOptions.api.getRowData();
  //   const updatedRowData = rowData.map(row => {
  //     const newData = {};
  //     newColumnsArray.forEach(newCol => {
  //       newData[newCol.field] = newCol.defaultValue || ''; // Use a default value or leave empty
  //     });
  //     return { ...row, ...newData };
  //   });
  //   gridOptions.api.setRowData(updatedRowData);
  // }

  return (
    <div>
      {show ? (
        <>
          <div className="flex justify-end items-center md:mr-10">
            <Button
              className="text-redBase border-redBase"
              onClick={() => setShow(false)}
            >
              Hide Filter
            </Button>
          </div>
          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormSelectField2}
              label="customer-list"
              name="customer"
              options={customerDropDown}
              placeholder="Select Customer list"
              isMulti
            />
            <Button onClick={handleAddCustomer}>Add Customer</Button>
          </div>
        </>
      ) : (
        <div className="flex justify-end items-center md:mr-10">
          <Button
            className="text-primaryBase border-primaryDark"
            onClick={() => setShow(true)}
          >
            Show Filter
          </Button>
        </div>
      )}
    </div>
  );
}

export default reduxForm({
  form: "column-filter",
  enableReinitialize: true,
})(ColumnFilter);
